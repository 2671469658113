import React, {useRef} from 'react'
import type {
  StripeAddressElement,
  StripeAddressElementChangeEvent,
} from '@stripe/stripe-js'
import * as WebUI from '@cheddarup/web-ui'
import {AddressElement} from 'src/components/Stripe/AddressElement'

import useCart, {useEnhancedUpdateCartMutation} from '../hooks/useCart'
import usePublicCollection from '../hooks/usePublicCollection'
import {CheckoutSteppedPanel} from './CheckoutSteppedPanel'
import {useIsAuthed} from 'src/hooks/useAuthToken'
import {api} from '@cheddarup/api-client'
import {PayerBrandKitColors} from '@cheddarup/core'
import {getCUReadableColor} from 'src/helpers/color-utils'

export interface AddressPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  hasPaymentIntent: boolean
  brandKitColors: PayerBrandKitColors
}

export const AddressPanel = ({
  hasPaymentIntent,
  brandKitColors,
  className,
  ...restProps
}: AddressPanelProps) => {
  const isAuthed = useIsAuthed()
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const [updateCartMutation, updateCartAsync] = useEnhancedUpdateCartMutation()
  const userAddressQuery = api.auth.session.useQuery(undefined, {
    enabled: isAuthed,
    select: (session) => {
      const address =
        session.user.personal_address ?? session.user.business_address

      return {
        address: `${address.line1} ${address.line2 ?? ''}`.trim(),
        city: address.city,
        country: address.country,
        state: address.state,
        zip: address.postal_code,
        name: session.user.full_name,
      }
    },
  })
  const addressElementRef = useRef<StripeAddressElement | null>(null)

  if (
    !publicCollection.shippingOptions.shipToEnabled ||
    cart?.shippingInfo.shippingMethod === 'localPickup'
  ) {
    return null
  }

  const shippingMethod = cart?.shippingInfo.shippingMethod ?? 'toMe'
  const defaultAddress = cart?.shippingInfo.shipTo
    ? shipToAddressToAddressElementValue(cart.shippingInfo.shipTo)
    : userAddressQuery.data
      ? shipToAddressToAddressElementValue(userAddressQuery.data)
      : undefined

  return (
    <CheckoutSteppedPanel
      // HACK: the z-indices here and on AddressElement are here to fix
      // the address autosuggest element to render on top of the rest
      className={WebUI.cn('!z-[1] overflow-visible', className)}
      step="address"
      heading="Shipping Address"
      {...restProps}
    >
      {({isActive, isCompleted}, flowActions) =>
        isActive ? (
          <div className="flex flex-col gap-4">
            {hasPaymentIntent ? (
              <AddressElement
                key={
                  cart?.member
                    ? `${cart.member.email}-${cart.member.name}`
                    : 'address-element'
                }
                className="!z-[2]"
                options={{
                  mode: 'shipping',
                  defaultValues: {
                    name: cart?.shippingInfo.shipTo?.name ?? cart?.member?.name,
                    address: {
                      country: defaultAddress?.country ?? 'US',
                      postal_code: defaultAddress?.postal_code ?? undefined,
                      state: defaultAddress?.state ?? undefined,
                      city: defaultAddress?.city ?? undefined,
                      line1: defaultAddress?.line1 ?? undefined,
                      line2: defaultAddress?.line2 ?? undefined,
                    },
                  },
                }}
                onReady={(addressElementInstance) => {
                  addressElementRef.current = addressElementInstance
                }}
              />
            ) : (
              <WebUI.Loader />
            )}

            <WebUI.Button
              className="self-start"
              roundness="capsule"
              loading={updateCartMutation.isPending}
              style={{
                backgroundColor: brandKitColors.primaryButton,
                color: getCUReadableColor(brandKitColors.primaryButton),
              }}
              onClick={async () => {
                const res = await addressElementRef.current?.getValue()
                if (!res?.complete) {
                  return
                }

                await updateCartAsync({
                  body: {
                    shippingMethod,
                    shipTo: addressElementValueToShipToAddress(res.value),
                  },
                })

                flowActions.proceed()
              }}
            >
              Continue
            </WebUI.Button>
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            <AddressSummary />
            {isCompleted && (
              <WebUI.Button
                className="text-ds-sm"
                variant="link"
                onClick={() => flowActions.setActiveStep('address')}
              >
                Change
              </WebUI.Button>
            )}
          </div>
        )
      }
    </CheckoutSteppedPanel>
  )
}

// MARK: – AddressSummary

const AddressSummary = (props: React.ComponentPropsWithoutRef<'div'>) => {
  const {cart} = useCart()

  if (!cart) {
    return null
  }

  return (
    <div {...props}>
      <WebUI.Text className="text-ds-sm">
        {cart.shippingInfo.shipTo?.name}
      </WebUI.Text>
      <WebUI.Text className="text-ds-sm">
        {[
          cart.shippingInfo.shipTo?.address,
          cart.shippingInfo.shipTo?.city,
          `${cart.shippingInfo.shipTo?.state ?? ''} ${
            cart.shippingInfo.shipTo?.zip ?? ''
          }`,
          cart.shippingInfo.shipTo?.country,
        ]
          .filter((str) => typeof str === 'string' && str.length > 0)
          .join(', ')}
      </WebUI.Text>
    </div>
  )
}

// MARK: – Helpers

export const useAddressMode = () => {
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()

  const shippingMethod = cart?.shippingInfo.shippingMethod ?? 'toMe'

  return publicCollection.shippingOptions.shipToEnabled &&
    shippingMethod === 'toMe'
    ? 'shipping'
    : 'billing'
}

const addressElementValueToShipToAddress = (
  elValue: StripeAddressElementChangeEvent['value'],
): Api.CartShippingTo => ({
  name: elValue.name,
  address: `${elValue.address.line1} ${elValue.address.line2 ?? ''}`,
  city: elValue.address.city,
  country: elValue.address.country,
  state: elValue.address.state,
  zip: elValue.address.postal_code,
})

const shipToAddressToAddressElementValue = (address: Api.CartShippingTo) => ({
  line1: address.address ?? null,
  line2: null,
  city: address.city ?? null,
  country: address.country ?? null,
  state: address.state ?? null,
  postal_code: address.zip ?? null,
})
