import * as Yup from 'yup'
import * as Util from '@cheddarup/util'
import {useNavigate, useParams} from 'react-router-dom'
import {useFormik, useLiveRef, useUpdateEffect} from '@cheddarup/react-util'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useCreateTabAttachmentMutation,
  useCreateTabMutation,
  useDeleteTabAttachmentMutation,
  useUpdateTabMutation,
} from '@cheddarup/api-client'
import {bannedWordIncludedIn} from 'src/helpers/inputHelpers'
import {uploadCollectionLogo} from 'src/helpers/file-uploads'

import {CollectionForm} from './CollectionForm'
import CollectionAdditionalInformationBlock from './CollectionAdditionalInformationBlock'
import BrandingUpsellPanel from './BrandingUpsellPanel'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'
import {uploadTabAttachment} from '@cheddarup/core'
import {guessError} from 'src/helpers/error-utils'

export interface CollectionFormValues {
  name: string
  ownerId: number | null
  folderId: number | null | 'NONE'
  headerImages: Api.S3Image[]
  uploadedLogo: Blob | null
  uploadedLogoCrop: Api.CropDetails | null
  description: string
  attachments: Array<File | Api.TabAttachment>
  videoLink: string
}

export type CollectionFormFormik = ReturnType<
  typeof useFormik<CollectionFormValues>
>

const CollectionDetailsPage = () => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const [managerRoleId] = useManagerRoleId()
  const collectionQuery = api.tabs.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
      },
    },
    {
      enabled: !!urlParams.collection,
    },
  )
  const attachmentsQuery = api.tabAttachments.list.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
      },
    },
    {
      enabled: !!urlParams.collection,
    },
  )
  const createCollectionMutation = useCreateTabMutation()
  const updateCollectionMutation = useUpdateTabMutation()
  const createTabAttachmentMutation = useCreateTabAttachmentMutation()
  const deleteAttachmentMutation = useDeleteTabAttachmentMutation()
  const growlActions = WebUI.useGrowlActions()

  const collection = collectionQuery.data

  const initialValues: CollectionFormValues = {
    name: collection?.name ?? '',
    ownerId: collection?.user_id ?? null,
    folderId: collection ? (collection.folder?.id ?? null) : 'NONE',
    description: collection?.description ?? '',
    headerImages: collection?.header_images ?? [],
    uploadedLogo: null,
    uploadedLogoCrop: null,
    attachments: attachmentsQuery.data ?? [],
    videoLink: collection?.options.infoBlockSettings?.video?.enabled
      ? collection.options.infoBlockSettings.video.link
      : '',
  }

  const formik = useFormik<CollectionFormValues>({
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      folderId: Yup.mixed().not(['NONE'], 'Required').nullable(),
      description: Yup.string().test(
        'misses-banned-words',
        'Your description contains restricted language (mention of another payment platform) and will not appear on your collection page. This field is closely monitored and if a workaround is entered, your collection may be at risk of being shut down, with payments auto refunded.',
        (v) => !bannedWordIncludedIn(v),
      ),
    }),
    initialValues,
    onSubmit: async (values) => {
      const getLogoPayload = async (collectionId: number) => {
        if (values.uploadedLogo) {
          const uploadedLogo = await uploadCollectionLogo({
            tabId: collectionId,
            image: values.uploadedLogo,
            thumbnail: {
              order: 0,
              cropDetails: values.uploadedLogoCrop ?? {},
            },
          })

          return {
            logo_id: uploadedLogo.id,
          }
        }
        return {}
      }

      const saveCollection = collection
        ? updateCollectionMutation.mutateAsync
        : createCollectionMutation.mutateAsync

      try {
        const savedCollection = await saveCollection({
          pathParams: {
            tabId: collection?.id as any,
          },
          body: {
            do_not_publish: true,
            name: values.name,
            description: values.description,
            folder_id: values.folderId === 'NONE' ? null : values.folderId,
            owner: values.ownerId,
            // biome-ignore lint/style/noNonNullAssertion:
            imageCarouselIds: values.headerImages.map((hi) => hi.id!),
            featuredImageId: values.headerImages[0]?.id,
            options: {
              infoBlockSettings: {
                ...collection?.options.infoBlockSettings,
                video: {
                  enabled: values.videoLink.length > 0,
                  link:
                    values.videoLink ||
                    collection?.options.infoBlockSettings?.video?.link ||
                    '',
                  platform: '',
                  order:
                    collection?.options.infoBlockSettings?.video?.order ??
                    Number.MAX_SAFE_INTEGER,
                },
              },
            },
          },
        })

        const attachmentsToDelete = Util.differenceWith(
          attachmentsQuery.data ?? [],
          values.attachments.filter((a) => 'id' in a),
          (a, b) => a.id === b.id,
        )

        const [logoPayload] = await Promise.all([
          getLogoPayload(savedCollection.id),
          ...values.attachments
            .filter((a) => a instanceof File)
            .map((f) =>
              uploadTabAttachment(savedCollection.id, f, {
                userId: managerRoleId ?? undefined,
                createAttachmentQueryFn:
                  createTabAttachmentMutation.mutateAsync,
              }),
            ),
          ...attachmentsToDelete.map((a) =>
            deleteAttachmentMutation.mutateAsync({
              pathParams: {
                tabId: savedCollection.id,
                attachmentId: a.id,
              },
            }),
          ),
        ])

        if (Object.keys(logoPayload).length > 0) {
          await updateCollectionMutation.mutateAsync({
            pathParams: {
              tabId: savedCollection.id,
            },
            body: logoPayload,
          })
        }

        if (!urlParams.collection) {
          navigate(`/collection/${savedCollection.id}/details`, {
            replace: true,
          })
        }

        navigate(`/collection/${savedCollection.id}/details/items`)
        return savedCollection
      } catch (err: any) {
        growlActions.clear()
        growlActions.show('error', {
          title: 'Error',
          body: guessError(err).message,
        })
        return null
      }
    },
  })

  const initialValuesRef = useLiveRef(initialValues)
  const resetFormik = formik.resetForm
  useUpdateEffect(() => {
    if (collectionQuery.isSuccess && attachmentsQuery.isSuccess) {
      resetFormik({values: initialValuesRef.current})
    }
  }, [collectionQuery.isSuccess, attachmentsQuery.isSuccess, resetFormik])

  return (
    <form
      className="flex min-h-0 grow flex-col items-center overflow-y-auto"
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
      noValidate
    >
      <div className="flex w-full max-w-screen-xl flex-auto flex-row flex-wrap justify-center gap-6 px-4 py-8 md:flex-nowrap">
        <CollectionForm
          className="max-w-3xl flex-[1_0_0px]"
          collection={collection}
          formik={formik}
        />
        <div className="flex flex-col gap-6 [&_>_.Panel]:max-w-[360px] [&_>_.Panel]:flex-0">
          <CollectionAdditionalInformationBlock
            collectionId={collection?.id}
            formik={formik}
          />
          {!managerRoleId && <BrandingUpsellPanel />}
        </div>
      </div>
      <WebUI.PageToolbar className="w-full">
        <WebUI.PageToolbarSubmitButton
          loading={formik.isSubmitting}
          type="submit"
        >
          Save
        </WebUI.PageToolbarSubmitButton>
      </WebUI.PageToolbar>
    </form>
  )
}

export default CollectionDetailsPage
