import {useNavigate, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {
  ItemVariantOptionValuesList,
  OrderSummaryLayout,
  OrderSummarySidebarHeader,
  PaymentFormTable,
  PaymentItemTable,
  PaymentOverview,
  PaymentSignupTable,
  SignupPaymentSummary,
  useSelectedPaymentObject,
  useSelectedTermAndHistoryTabItemId,
} from 'src/components/OrderSummaryLayout'
import {FieldSetList} from 'src/components/FieldSetList'
import OrderSummaryTermsHistory from 'src/components/OrderSummaryTermsHistory'
import {PaymentObjectFieldsEdit} from 'src/views/OrderSummaryPage/PaymentObjectFieldsEdit'
import React from 'react'
import {TicketOverviewCard} from 'src/components/TicketOverviewCard'
import {PayerOverview} from 'src/components'

import {LinkButton} from '../../components/LinkButton'

const PaymentDetailsPage = () => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const paymentQuery = api.payments.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      paymentId: urlParams.payment!,
    },
  })
  const [selectedPaymentObject] = useSelectedPaymentObject('toOthers')
  const [selectedTermAndHistoryTabItemId] = useSelectedTermAndHistoryTabItemId()

  const pis = paymentQuery.data?.payment_items ?? []
  const paymentItems =
    (pis.filter((pi) => !!pi.tab_item) as unknown as Api.TabItemPayment[]) ?? []
  const paymentForms =
    (pis.filter((pi) => !!pi.tab_form) as Api.TabFormPayment[]) ?? []
  const paymentSignups = Util.sort(
    pis.filter((pi) => !!pi.time_slot) as Api.TabSignupPayment[],
  ).asc((pts) => pts.time_slot.options.startTime)
  const selectedTabObjectDescription = (
    selectedPaymentObject?.tab_form ??
    selectedPaymentObject?.tab_item ??
    selectedPaymentObject?.time_slot?.spot.signup
  )?.description

  return (
    <OrderSummaryLayout
      paymentType="toOthers"
      onDidHide={() => navigate('../..')}
      sidebar={
        paymentQuery.data && selectedPaymentObject ? (
          <WebUI.VStack
            className={
              'gap-4 *:border-b *:pb-4 [&_>_*:last-child:not(.TicketOverviewCard)]:pb-0 [&_>_*:last-child]:border-b-0'
            }
          >
            <OrderSummarySidebarHeader paymentType="toOthers" />
            {selectedPaymentObject.detail &&
            'itemType' in selectedPaymentObject.detail &&
            selectedPaymentObject.detail.itemType === 'ticket' ? (
              <>
                <WebUI.VStack className="gap-2">
                  <WebUI.Text className="text-ds-sm">Payment by</WebUI.Text>
                  <PayerOverview
                    avatarHidden
                    tabMember={paymentQuery.data?.tab_member}
                  />
                </WebUI.VStack>
                <TicketOverviewCard
                  paymentType="toOthers"
                  collectionId={selectedPaymentObject.id}
                  paymentObject={
                    selectedPaymentObject as unknown as Api.TabItemPayment
                  }
                />
              </>
            ) : (
              <>
                {!!selectedTabObjectDescription && (
                  <WebUI.MarkdownParagraph
                    className="text-ds-sm"
                    markdown={selectedTabObjectDescription}
                  />
                )}
                {selectedPaymentObject.time_slot && (
                  <SignupPaymentSummary
                    className="text-ds-sm"
                    paymentItem={selectedPaymentObject as Api.TabSignupPayment}
                  />
                )}
                {selectedPaymentObject.time_slot ? (
                  <PaymentObjectFieldsEdit
                    className={
                      '[&_.List]:h-auto [&_.List]:overflow-visible [&_.RichTextEditor]:line-clamp-[8] [&_.RichTextEditor]:text-ds-sm'
                    }
                    collectionId={
                      selectedPaymentObject.time_slot.spot.signup.tab_id
                    }
                    paymentId={selectedPaymentObject.payment_id}
                    paymentObject={selectedPaymentObject}
                  />
                ) : (
                  <FieldSetList
                    size="compact"
                    variant="organizer"
                    EmptyStateViewComponent={React.Fragment}
                    defaultValues={Util.mapToObj(
                      selectedPaymentObject.item_field_views,
                      (ifv) => [ifv.id, ifv.value],
                    )}
                    fields={selectedPaymentObject.item_field_views}
                    fieldSets={
                      (
                        selectedPaymentObject.tab_form ??
                        selectedPaymentObject.tab_item
                      )?.options.fieldSets ?? []
                    }
                  />
                )}
                <ItemVariantOptionValuesList paymentType="toOthers" />
              </>
            )}
          </WebUI.VStack>
        ) : selectedTermAndHistoryTabItemId ? (
          <OrderSummaryTermsHistory
            paymentMethodSelection
            tabItemId={selectedTermAndHistoryTabItemId}
            payment={paymentQuery.data}
          />
        ) : undefined
      }
    >
      <WebUI.VStack className="gap-4 px-4 py-8 sm:p-8">
        <WebUI.Heading as="h3">Order Summary</WebUI.Heading>
        <LinkButton
          className="self-start"
          variant="default"
          target="_blank"
          to={`/pdf/payments/${urlParams.payment}/report`}
        >
          Print
        </LinkButton>
      </WebUI.VStack>

      <WebUI.Separator variant="primary" />

      {!!paymentQuery.data && (
        <>
          <PayerOverview
            className="p-6 sm:p-8"
            tabMember={paymentQuery.data.tab_member}
          />

          <WebUI.Separator variant="primary" />

          <PaymentOverview
            className="px-4 py-8 sm:p-8"
            paymentType="toOthers"
            payment={paymentQuery.data}
          />
        </>
      )}

      {!!paymentQuery.data && (
        <WebUI.VStack className="gap-8 sm:p-8">
          {paymentItems.length > 0 && (
            <PaymentItemTable
              paymentType="toOthers"
              payment={paymentQuery.data}
              paymentItems={paymentItems}
              shippingInfo={paymentQuery.data.shipping_info}
              refunds={paymentQuery.data.refunds ?? []}
            />
          )}
          {paymentForms.length > 0 && (
            <PaymentFormTable paymentType="toOthers" data={paymentForms} />
          )}
          {paymentSignups.length > 0 && (
            <PaymentSignupTable
              collectionId={paymentQuery.data.tab.id}
              paymentType="toOthers"
              data={paymentSignups}
            />
          )}
        </WebUI.VStack>
      )}
    </OrderSummaryLayout>
  )
}

export default PaymentDetailsPage
