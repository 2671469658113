import React, {useContext, useMemo, useRef} from 'react'
import {
  Radio as ReakitRadio,
  RadioGroup as ReakitRadioGroup,
  RadioGroupOptions as ReakitRadioGroupOptions,
  RadioInitialState as ReakitRadioInitialState,
  RadioOptions as ReakitRadioOptions,
  RadioStateReturn as ReakitRadioStateReturn,
  useRadioState,
} from 'reakit'
import {
  ForwardRefComponent,
  useForkRef,
  useLiveRef,
  useUpdateEffect,
} from '@cheddarup/react-util'
import {cva} from 'class-variance-authority'

import {HStack, VStack} from './Stack'
import {Text} from './Text'
import {PhosphorIcon} from '../icons'
import {VariantsProps, cn} from '../utils'

export interface InternalRadioGroupContextValue
  extends ReakitRadioStateReturn,
    Pick<RadioProps, 'disabled' | 'size' | 'variant'> {
  name?: string
}

export const InternalRadioGroupContext = React.createContext(
  {} as InternalRadioGroupContextValue,
)

export interface RadioGroupProps
  extends Pick<ReakitRadioInitialState, 'state'>,
    Partial<ReakitRadioGroupOptions>,
    Pick<RadioProps, 'size' | 'variant'> {
  name?: string
  defaultState?: ReakitRadioInitialState['state']
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  children?:
    | React.ReactNode
    | ((radio: ReakitRadioStateReturn) => React.ReactNode)
}

export const RadioGroup = React.forwardRef(
  (
    {
      className,
      name,
      disabled,
      size,
      variant,
      defaultState,
      state,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const radio = useRadioState({state: state ?? defaultState})
    const radioRef = useLiveRef(radio)

    useUpdateEffect(() => {
      if (state != null) {
        radioRef.current.setState(state)
      }
    }, [state])

    const contextValue = useMemo(
      () => ({
        ...radio,
        name,
        disabled,
        size,
        variant,
      }),
      [name, disabled, radio, size, variant],
    )

    return (
      <InternalRadioGroupContext.Provider value={contextValue}>
        <ReakitRadioGroup
          ref={forwardedRef}
          name={name}
          className={cn('RadioGroup', 'flex flex-col gap-3', className)}
          {...radio}
          {...(restProps as any)}
        >
          {typeof children === 'function' ? children(radio) : children}
        </ReakitRadioGroup>
      </InternalRadioGroupContext.Provider>
    )
  },
) as ForwardRefComponent<typeof VStack, RadioGroupProps>

export const radio = cva(
  [
    'relative cursor-pointer items-center gap-3',
    // TODO: check if this works
    'has-[:[aria-checked=true]]:text-gray800',
    'has-[:disabled]:cursor-not-allowed has-[:disabled]:opacity-50',
    // TODO: check if this works
    `has-[:[aria-disabled=true]]:cursor-not-allowed
    has-[:[aria-disabled=true]]:opacity-50`,
  ],
  {
    variants: {
      size: {
        default: 'text-ds-base',
        compact: 'text-ds-sm',
        large: 'font-light text-ds-md',
      },
    },
  },
)
export const radioIcon = cva(
  [
    `relative h-[1.375em] w-[1.375em] shrink-0 items-center gap-3 rounded-full
    bg-natural-95 shadow-[inset_0_0_0_1px_theme(colors.natural.70)]`,
    'transition-all duration-100 ease-in-out',
    '[&[class]]:ml-0',
    'peer-disabled/input:cursor-not-allowed peer-disabled/input:opacity-50',
    'peer-aria-disabled/input:cursor-not-allowed peer-aria-disabled/input:opacity-50',
    'peer-focus/input:peer-data-[focus-visible-added]/input:shadow-[inset_0_0_0_1px_theme(colors.teal.50)]',
    'group-hover:bg-inputHoverBackground',
  ],
  {
    variants: {
      variant: {
        default: [
          `peer-aria-checked/input:bg-orange-50
          peer-aria-checked/input:shadow-none`,
        ],
        secondary:
          'peer-aria-checked/input:bg-teal-50 peer-aria-checked/input:shadow-none',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface RadioProps
  extends VariantsProps<typeof radio>,
    VariantsProps<typeof radioIcon>,
    Partial<ReakitRadioOptions> {
  checkedRadioIconStyles?: React.CSSProperties
}

export const Radio = React.forwardRef(
  (
    {
      size: sizeProp,
      variant: variantProp,
      className,
      disabled: disabledProp,
      onMouseDown,
      children,
      checkedRadioIconStyles,
      ...restProps
    },
    forwardedRef,
  ) => {
    const ownRef = useRef<HTMLInputElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)
    const {
      name,
      disabled,
      size: sizeContext,
      variant: variantContext,
      ...radioState
    } = useContext(InternalRadioGroupContext)

    const size = sizeProp ?? sizeContext
    const variant = variantProp ?? variantContext

    return (
      <HStack
        className={cn(
          `Radio Radio--${variant}-${size} group inline-flex`,
          radio({size}),
          className,
        )}
        onMouseDown={onMouseDown}
        onClick={() => ownRef.current?.click()}
      >
        <ReakitRadio
          ref={ref}
          className={cn(
            'Radio-input',
            'peer/input -z-[1] absolute h-px w-px overflow-hidden opacity-0',
          )}
          name={name}
          disabled={disabledProp ?? disabled}
          {...radioState}
          {...restProps}
        />

        <div
          className={cn(
            'Radio-icon',
            radioIcon({variant}),
            size === 'compact' && 'h-[1.125em] w-[1.125em]',
          )}
          style={
            radioState.state === restProps.value
              ? checkedRadioIconStyles
              : undefined
          }
        >
          <PhosphorIcon
            aria-hidden="true"
            className={cn(
              'Radio-checkIcon',
              '-translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 hidden text-natural-100',
              '[.Radio-input[aria-checked=true]_~_.Radio-icon_>_&]:block',
              size === 'compact' && 'text-xs',
            )}
            icon="check-bold"
            style={
              radioState.state === restProps.value
                ? checkedRadioIconStyles
                : undefined
            }
          />
        </div>

        {typeof children === 'string' ? (
          <Text className="Radio-text">{children}</Text>
        ) : (
          children
        )}
      </HStack>
    )
  },
) as ForwardRefComponent<'input', RadioProps>
