import * as WebUI from '@cheddarup/web-ui'
import {tailwindConfig} from '@cheddarup/tailwind-config'
import React from 'react'
import {api} from '@cheddarup/api-client'
import {SharpAvatar} from 'src/components/SharpAvatar'
import {useManagerRole} from 'src/components/ManageRoleProvider'
import {AbsoluteLinkButton} from 'src/components/LinkButton'
import * as Util from '@cheddarup/util'

interface ManagerAccountsProps
  extends React.ComponentPropsWithoutRef<'button'> {
  disclosureVariant?: 'profile' | 'text'
  menuPlacement?: WebUI.MenuProps['placement']
}

export const ManagerAccounts: React.FC<ManagerAccountsProps> = ({
  disclosureVariant = 'text',
  menuPlacement = 'auto',
  className,
  ...props
}) => {
  const [managerRole, setManagerRoleId] = useManagerRole()
  const sessionQuery = api.auth.session.useQuery()

  if (!sessionQuery.data || sessionQuery.data.manager_roles.length === 0) {
    return null
  }

  const personalAccountProfileItem = (
    <AccountProfileItem
      isPersonal
      managerRole={{
        id: sessionQuery.data.user.id,
        name: sessionQuery.data.user.display_name ?? '',
        profile_pic: sessionQuery.data.user.profile_pic ?? null,
      }}
    />
  )

  return (
    <WebUI.Menu placement={menuPlacement}>
      <WebUI.MenuButton
        className={WebUI.cn(
          disclosureVariant === 'profile' && 'w-full justify-between p-4',
        )}
        variant="text"
        iconAfter={
          <WebUI.PhosphorIcon
            icon="caret-down"
            width={{profile: 20, text: 16}[disclosureVariant]}
          />
        }
        {...props}
      >
        {disclosureVariant === 'text' ? (
          <WebUI.Text className="text-ds-xs">Switch Account</WebUI.Text>
        ) : managerRole ? (
          <AccountProfileItem managerRole={managerRole} />
        ) : (
          personalAccountProfileItem
        )}
      </WebUI.MenuButton>
      <WebUI.MenuList className="[&_>_.MenuList-inner_>_.MenuList-body_>_.MenuItem]:min-h-15">
        <div className="px-4 py-1 font-normal text-ds-xs uppercase">
          Switch To
        </div>
        {sessionQuery.data.manager_roles
          .filter((mr) => mr.id !== managerRole?.id)
          .map((mr) => (
            <WebUI.MenuItem key={mr.id} onClick={() => setManagerRoleId(mr.id)}>
              <AccountProfileItem managerRole={mr} />
            </WebUI.MenuItem>
          ))}
        {!!managerRole && (
          <WebUI.MenuItem onClick={() => setManagerRoleId(null)}>
            {personalAccountProfileItem}
          </WebUI.MenuItem>
        )}
      </WebUI.MenuList>
    </WebUI.Menu>
  )
}

// MARK: - AccountProfileItem

interface AccountProfileItemProps
  extends React.ComponentPropsWithoutRef<'div'> {
  isPersonal?: boolean
  managerRole: Pick<Api.ManagerRole, 'id' | 'name' | 'profile_pic'>
  avatarSize?: number
  showPlan?: boolean
  plan?: string
}

export const AccountProfileItem: React.FC<AccountProfileItemProps> = ({
  isPersonal = false,
  avatarSize = 36,
  showPlan = false,
  plan = 'free',
  managerRole,
  className,
  ...restProps
}) => (
  <div
    className={WebUI.cn(
      'AccountProfileItem relative flex items-center gap-2 overflow-hidden',
      className,
    )}
    {...restProps}
  >
    <SharpAvatar
      className={WebUI.cn(
        'AccountProfileItem-avatar flex-0',
        !isPersonal && '[&_.Avatar-label]:bg-teal-50',
      )}
      image={managerRole.profile_pic}
      name={managerRole.name}
      size={avatarSize}
    />
    <div className="AccountProfileItem-content flex min-w-0 flex-col gap-0_5">
      <WebUI.Ellipsis className="font-bold text-ds-sm">
        {managerRole.name}
      </WebUI.Ellipsis>
      <span className="font-light text-ds-xs text-gray-500">
        {isPersonal ? 'Personal Account' : 'Manager Account'}
      </span>
    </div>
    {isPersonal && showPlan && (
      <AbsoluteLinkButton
        variant={
          ({team: 'secondary', pro: 'secondaryAlt', free: 'default'}[plan] ??
            'default') as any
        }
        style={{
          color:
            plan === 'free'
              ? tailwindConfig.theme.colors.natural[100]
              : tailwindConfig.theme.colors.tint,
          opacity: 'inherit',
        }}
        iconBefore={plan !== 'free' && <WebUI.PhosphorIcon icon="star" />}
        disabled={plan === 'team'}
        size="compact"
        to={plan === 'team' ? '#' : 'i/plans'}
      >
        {Util.capitalize(plan === 'free' ? 'basic' : plan)}
      </AbsoluteLinkButton>
    )}
  </div>
)
