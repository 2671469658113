import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {UserAvatar, UserMenuHeading, UserMenuLinkButton} from '../UserMenu'
import {
  AccountProfileItem,
  ManagerAccounts,
} from 'src/views/CollectionsPage/components/ManagerAccounts'
import {LinkButton} from '../LinkButton'
import {useManagerRole} from '../ManageRoleProvider'
import {isAccountDetailsFieldsFilled} from '@cheddarup/core'

export const MyAccountSidebar = () => {
  const hasAlertsQuery = api.alerts.list.useQuery(undefined, {
    select: (alerts) => alerts.length > 0,
  })
  const isAnyFieldNeededQuery = api.auth.session.useQuery(undefined, {
    select: (session) =>
      session.stripe_data.fieldsNeeded.length > 0 &&
      isAccountDetailsFieldsFilled(session.user),
  })
  const sessionQuery = api.auth.session.useSuspenseQuery()

  const [managerRole] = useManagerRole()

  return (
    <WebUI.SideNav
      contentViewAppearance="rightToLeft"
      disclosure={
        <WebUI.DialogDisclosure
          className="h-auto px-0"
          iconAfter={<WebUI.PhosphorIcon icon="caret-down" />}
          variant="ghost"
        >
          <UserAvatar size={32} />
        </WebUI.DialogDisclosure>
      }
    >
      <div className="flex min-w-[300px] flex-col">
        <div className="flex flex-col gap-4 p-4">
          <AccountProfileItem
            isPersonal
            managerRole={{
              id: sessionQuery.data.user.id,
              name: sessionQuery.data.user.display_name ?? '',
              profile_pic: sessionQuery.data.user.profile_pic ?? null,
            }}
          />
          <div className="flex justify-between">
            {sessionQuery.data.manager_roles.length > 0 && (
              <ManagerAccounts menuPlacement="bottom-start" />
            )}
            <WebUI.Button
              as={LinkButton}
              variant="secondary"
              size="compact"
              to="/logout"
            >
              Log out
            </WebUI.Button>
          </div>
        </div>
        <WebUI.Separator />
        <div className="flex flex-col gap-4 p-4">
          <div className="flex flex-col gap-2">
            <UserMenuHeading className="mb-1">Account</UserMenuHeading>
            {sessionQuery.data?.partner_admin?.access && (
              <UserMenuLinkButton to="/reporting/dashboard">
                Partner Dashboard
              </UserMenuLinkButton>
            )}
            <UserMenuLinkButton to="my-account/display-name">
              <div className="flex gap-1">
                <span className="font-normal">Profile</span>
                {isAnyFieldNeededQuery.data && (
                  <WebUI.PhosphorIcon
                    className="text-orange-50"
                    icon="warning-circle-fill"
                    width={15}
                  />
                )}
              </div>
            </UserMenuLinkButton>
            <UserMenuLinkButton to="my-account/brand-kit-logo">
              Brand Kit
            </UserMenuLinkButton>
            <UserMenuLinkButton to="my-account/zapier-key">
              Integration
            </UserMenuLinkButton>
            <UserMenuLinkButton to="my-account/alerts">
              <div className="flex gap-1">
                <span className="font-normal">Alerts</span>
                {hasAlertsQuery.data && (
                  <WebUI.PhosphorIcon
                    className="text-orange-50"
                    width={15}
                    icon="warning-circle-fill"
                  />
                )}
              </div>
            </UserMenuLinkButton>

            {(!managerRole ||
              managerRole.permissions.address_book_and_message_center) && (
              <UserMenuLinkButton to="/address-book">
                Address Book
              </UserMenuLinkButton>
            )}
            <UserMenuLinkButton to="/payments">
              Your Order History
            </UserMenuLinkButton>
          </div>

          <WebUI.Separator variant="primary" />
          <div className="flex flex-col gap-2">
            <UserMenuHeading className="mb-1">Explore</UserMenuHeading>
            <UserMenuLinkButton to="/group">
              Create a group website
            </UserMenuLinkButton>
            <UserMenuLinkButton to="/templates">
              Start with a template
            </UserMenuLinkButton>
            <UserMenuLinkButton to="/reports">
              Create custom reports
            </UserMenuLinkButton>
          </div>
          <WebUI.Separator variant="primary" />
          <div className="flex flex-col gap-2">
            <UserMenuLinkButton to="/managers?addManager=1">
              Invite managers
            </UserMenuLinkButton>
            <WebUI.AnchorButton
              className="text-ds-xs text-teal-50"
              variant="text"
              href={'https://support.cheddarup.com'}
              target="_blank"
            >
              Get help
            </WebUI.AnchorButton>

            {!managerRole &&
              !sessionQuery.data?.capabilities.subscribed_to_team && (
                <UserMenuLinkButton to="i/plans">
                  Upgrade account
                </UserMenuLinkButton>
              )}
          </div>
        </div>
      </div>
    </WebUI.SideNav>
  )
}
