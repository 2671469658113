import React, {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {StringParam, useQueryParam, withDefault} from 'use-query-params'
import BalanceSummaryIcon from 'src/images/BalanceSummaryIcon.svg'
import FundCollectionIcon from 'src/images/FundCollectionIcon.svg'
import {LinkButton} from 'src/components/LinkButton'
import {formatGiftCardStatus} from 'src/helpers/gift-card-utils'
import {api} from '@cheddarup/api-client'
import {Elements} from 'src/components/Stripe'
import WithdrawFormContainer from 'src/views/withdraw/WithdrawPage/containers/WithdrawPageContentContainer/WithdrawFormContainer'
import {
  useManagerRole,
  useManagerRoleQuery,
} from 'src/components/ManageRoleProvider'
import {PersonaButton} from 'src/components/PersonaButton'

import {CollectionDepositForm, SendGiftCardContainer} from './components'
import {InquireVerificationCode} from 'src/components/InquireVerificationCode'

export const CollectionSummaryPage: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const urlParams = useParams()
  const media = WebUI.useMedia()
  const collectionId = Number(urlParams.collection)
  const [selectedTabId, setSelectedTabId] = useQueryParam(
    'selectedTabId',
    withDefault(StringParam, 'collection-summary'),
  )
  const {data: collection} = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })
  const {data: session} = api.auth.session.useQuery()

  const notCanadianUser = session?.user.currency === 'usd'

  return (
    <WebUI.Modal
      aria-label="Collection summary"
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg"
      onDidHide={() =>
        navigate(
          `${location.pathname.replace(
            `/i/collection/${urlParams.collection}/summary`,
            '',
          )}${location.search}`,
        )
      }
    >
      <WebUI.ModalCloseButton />
      <WebUI.ModalHeader>
        <WebUI.Heading as="h2">{collection?.name ?? ''}</WebUI.Heading>
      </WebUI.ModalHeader>

      <WebUI.Tabs
        className="grow overflow-x-hidden [&_>_.TabList_.Tab_.Button-iconBefore]:mr-5 [&_>_.TabPanel]:grow [&_>_.TabPanel]:overflow-y-auto [&_>_.TabPanel]:p-8"
        orientation={media.sm ? 'vertical' : 'horizontal'}
        selectedId={selectedTabId}
        onChangeSelectedId={(newSelectedId) => setSelectedTabId(newSelectedId)}
      >
        <WebUI.TabList aria-label="Navigation" className="shrink-0">
          <WebUI.Tab
            id="collection-summary"
            iconBefore={<img src={BalanceSummaryIcon} alt="" />}
          >
            Balance Summary
          </WebUI.Tab>
          <WebUI.Tab
            id="bank-transfer"
            iconBefore={
              <WebUI.PhosphorIcon
                className="text-teal-50"
                icon="bank"
                width={24}
              />
            }
          >
            Transfer to Bank
          </WebUI.Tab>
          {notCanadianUser && (
            <WebUI.Tab
              id="send-gift"
              iconBefore={
                <WebUI.PhosphorIcon
                  className="text-teal-50"
                  icon="gift"
                  width={24}
                />
              }
            >
              Send a Gift Card
            </WebUI.Tab>
          )}
          <WebUI.Tab
            id="fund-collection"
            iconBefore={<img src={FundCollectionIcon} alt="" />}
          >
            Fund Collection
          </WebUI.Tab>
        </WebUI.TabList>

        <WebUI.TabPanel tabId="collection-summary">
          <CollectionSummary
            collectionId={collectionId}
            onPrintCollectionSummaryReport={() => {
              const path = `/pdf/collection/${urlParams.collection}/summary-report`
              const url =
                import.meta.env.PUBLIC_URL ||
                `${window.location.protocol}//${window.location.hostname}${
                  window.location.port ? `:${window.location.port}` : ''
                }`
              const reportUrl = `${url}${path}`
              const reportWindow = window.open(reportUrl, 'blank')

              reportWindow?.focus()
            }}
          />
        </WebUI.TabPanel>

        <WebUI.TabPanel tabId="fund-collection">
          <div className="flex flex-col gap-6">
            <p className="max-w-screen-sm">
              Funding a collection allows you to add a balance to a collection.
              You would do this in order to make a refund if you&apos;ve already
              withdrawn all collected funds on this collection, or to correct a
              negative balance due to a disputed payment on this collection.
            </p>
            <Elements>
              <InquireVerificationCode>
                {(verificationHelpers) => (
                  <CollectionDepositForm
                    className="py-8"
                    tabId={Number(urlParams.collection)}
                    verificationHelpers={verificationHelpers}
                  />
                )}
              </InquireVerificationCode>
            </Elements>
          </div>
        </WebUI.TabPanel>

        <WebUI.TabPanel tabId="bank-transfer">
          {collection ? (
            <EnsureTransferConditions>
              <WithdrawFormContainer collection={collection} />
            </EnsureTransferConditions>
          ) : (
            <TabPanelLoader />
          )}
        </WebUI.TabPanel>

        <WebUI.TabPanel tabId="send-gift">
          {collection ? (
            <EnsureTransferConditions transferType="gift">
              <SendGiftCardContainer collection={collection} />
            </EnsureTransferConditions>
          ) : (
            <TabPanelLoader />
          )}
        </WebUI.TabPanel>
      </WebUI.Tabs>
    </WebUI.Modal>
  )
}

// MARK: - EnsureTransferConditions

interface EnsureTransferConditionsProps {
  children: React.ReactNode
  transferType?: 'bank' | 'gift'
}

const EnsureTransferConditions: React.FC<EnsureTransferConditionsProps> = ({
  children,
  transferType = 'bank',
}) => {
  const [managerRoleQuery] = useManagerRoleQuery()

  const {data: noExternalAccountLinked} = api.externalAccounts.list.useQuery(
    undefined,
    {
      enabled:
        !managerRoleQuery.isPending &&
        (!managerRoleQuery.data ||
          managerRoleQuery.data.permissions.role === 'admin'),
      select: (externalAccounts) =>
        externalAccounts.banks.length + externalAccounts.cards.length === 0,
    },
  )
  const {data: session} = api.auth.session.useQuery()

  const requirePersona =
    !managerRoleQuery.data &&
    session?.user.profile?.persona?.required &&
    !session?.user.profile?.persona?.completed

  if (noExternalAccountLinked || noExternalAccountLinked == null) {
    return <BankAccountCta isBankTransfer={transferType === 'bank'} />
  }

  if (requirePersona) {
    return <PersonaCta />
  }

  if (
    !managerRoleQuery.data &&
    (!session?.stripe_data.payoutsEnabled ||
      (session?.stripe_data?.fieldsNeeded?.length || 0) > 0)
  ) {
    return <MoreInformationCta />
  }

  return <>{children}</>
}

// MARK: - PersonaCta

const PersonaCta: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  className,
  ...restProps
}) => {
  const navigate = useNavigate()
  return (
    <CTAWrapper
      heading="Verify your identity to expedite withdrawals"
      subHeading="Upload a government ID in combination with a live photo. You will only
      need to do this one time."
      {...restProps}
    >
      <PersonaButton
        className="self-start"
        variant="primary"
        onComplete={() => setTimeout(() => navigate('/withdraw?identity=1'), 0)}
      />
    </CTAWrapper>
  )
}

// MARK: – MoreInformationCta

const MoreInformationCta: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  className,
  ...restProps
}) => (
  <CTAWrapper
    heading="More information, please."
    subHeading="Looks like we need some more information before we can enable withdrawals."
    {...restProps}
  >
    <LinkButton
      className="self-start"
      variant="primary"
      to="my-account/account-details"
    >
      Go to Account Settings
    </LinkButton>
  </CTAWrapper>
)

// MARK: – BankAccountCta

const BankAccountCta: React.FC<
  React.ComponentPropsWithoutRef<'div'> & {isBankTransfer?: boolean}
> = ({className, isBankTransfer = true, ...restProps}) => {
  const [managerRole] = useManagerRole()
  return (
    <CTAWrapper
      heading={
        isBankTransfer
          ? managerRole
            ? 'No bank account linked.'
            : 'Where should we deposit your money?'
          : 'Add a bank account to send a gift card'
      }
      subHeading={
        isBankTransfer
          ? managerRole
            ? "Contact the account owner and let them know they'll need to link a bank account before withdrawals can be initiated."
            : "Please add your banking information so we know where to deposit the money you've collected."
          : 'A bank account is required to send a gift card. As the organizer, you can send some funds to your bank account and some funds to a gift card (or send multiple gift cards to multiple recipients!).'
      }
      {...restProps}
    >
      {managerRole ? (
        <div className="flex flex-col [&_>_.Text]:font-light [&_>_.Text_span]:font-normal">
          <WebUI.Text>
            <span>Account:</span> {managerRole.name}
          </WebUI.Text>
          <WebUI.Text>
            <span>Contact:</span> {managerRole.full_name},{' '}
            <WebUI.Anchor
              href={`mailto:${managerRole.email}?subject=Link a bank account to initiate withdrawals`}
            >
              {managerRole.email}
            </WebUI.Anchor>
          </WebUI.Text>
        </div>
      ) : (
        <LinkButton
          className="self-start"
          variant="default"
          to="my-account/withdrawal-settings"
        >
          Add a Bank Account
        </LinkButton>
      )}
    </CTAWrapper>
  )
}

// MARK: - CTAWrapper

interface CTAWrapperProps extends React.ComponentPropsWithoutRef<'div'> {
  heading: string
  subHeading: string
}

const CTAWrapper: React.FC<CTAWrapperProps> = ({
  className,
  heading,
  subHeading,
  children,
  ...restProps
}) => (
  <div
    className={WebUI.cn(
      'flex max-w-screen-sm flex-col gap-5 text-gray800',
      className,
    )}
    {...restProps}
  >
    <div className="flex flex-col gap-2">
      <WebUI.Heading className="text-ds-md" as="h4">
        {heading}
      </WebUI.Heading>
      <WebUI.Text className="font-light">{subHeading}</WebUI.Text>
    </div>
    {children}
  </div>
)

// MARK: TabPanelLoader

const TabPanelLoader: React.FC = () => (
  <div className="flex h-full flex-col items-center justify-center">
    <WebUI.Loader />
  </div>
)

// MARK: – CollectionSummary

interface CollectionSummaryProps {
  collectionId: number
  onPrintCollectionSummaryReport: () => void
}

const CollectionSummary: React.FC<CollectionSummaryProps> = ({
  collectionId,
  onPrintCollectionSummaryReport,
}) => {
  const [downloading, setDownloading] = useState(false)

  const {data: withdrawals} = api.withdrawals.list.useQuery({
    pathParams: {
      tabId: collectionId,
    },
  })
  const {data: payments} = api.tabPayments.list.useQuery({
    pathParams: {
      tabId: collectionId,
    },
    queryParams: {
      ever_disputed: true,
    },
  })
  const {data: collectionDeposits} = api.tabDeposits.list.useQuery({
    pathParams: {
      tabId: collectionId,
    },
  })
  const {data: session} = api.auth.session.useQuery()
  const {data: collection} = api.tabs.detail.useQuery({
    pathParams: {
      tabId: collectionId,
    },
  })

  const disputedPayments = payments?.data.filter(({dispute}) => !!dispute) ?? []
  const location = useLocation()
  const growlActions = WebUI.useGrowlActions()

  return (
    <div className="flex flex-col gap-3 font-body font-light">
      <div className="flex flex-row justify-between">
        <WebUI.Heading className="pb-1 pl-4" as="h3">
          Balance Summary
        </WebUI.Heading>
        <div className="flex flex-row gap-3">
          <WebUI.IconButton
            variant="secondary"
            loading={downloading}
            onClick={async () => {
              try {
                setDownloading(true)
                const res = await api.exports.paymentsXlsx.fetch({
                  pathParams: {
                    tabId: collectionId,
                  },
                })
                if (res.file_url) {
                  window.location.href = res.file_url
                }
                growlActions.show('success', {
                  title: 'Your report is in progress',
                  body:
                    res.message || 'Your report was downloaded to your device.',
                })
              } catch {
                // noop
              } finally {
                setDownloading(false)
              }
            }}
          >
            <WebUI.PhosphorIcon icon="download-simple" width={18} />
          </WebUI.IconButton>
          <WebUI.IconButton
            variant="secondary"
            onClick={() => onPrintCollectionSummaryReport()}
          >
            <WebUI.PhosphorIcon icon="printer" width={18} />
          </WebUI.IconButton>
        </div>
      </div>
      <div className="flex flex-col gap-4 rounded bg-gray100 p-5">
        <div className="flex flex-col">
          <TotalRow
            className="font-bold"
            label="Total Collected"
            value={Util.formatAmount(collection?.payments_total ?? 0)}
          />
          <WebUI.Text className="font-normal text-ds-xs text-gray750">
            {Util.pluralize(
              'payment',
              collection?.reportsAvailable.paymentCount ?? 0,
              true,
            )}
          </WebUI.Text>
        </div>

        <WebUI.Separator
          className="[&.Separator]:border-b-[3px]"
          variant="light"
        />

        <div className="flex flex-col gap-2">
          <TotalRow
            label="Collected Online"
            value={Util.formatAmount(collection?.online_total ?? 0)}
          />
          <CollectedRow
            className="max-w-[480px]"
            title="Credit Card"
            subtitle={`(cleared: ${Util.formatAmount(
              collection?.card_confirmed_total ?? 0,
            )}, pending: ${Util.formatAmount(collection?.card_pending_total ?? 0)})`}
            value={Util.formatAmount(
              (collection?.card_pending_total ?? 0) +
                (collection?.card_confirmed_total ?? 0),
            )}
          />
          <CollectedRow
            className="max-w-[480px]"
            title="eCheck"
            subtitle={`(cleared: ${Util.formatAmount(
              collection?.echeck_confirmed_total ?? 0,
            )}, pending: ${Util.formatAmount(
              collection?.echeck_pending_total ?? 0,
            )})`}
            value={Util.formatAmount(
              (collection?.echeck_pending_total ?? 0) +
                (collection?.echeck_confirmed_total ?? 0),
            )}
          />
        </div>

        <WebUI.Separator
          className="[&.Separator]:border-b-[3px]"
          variant="light"
        />

        <div className="flex flex-col gap-2">
          <TotalRow
            label="Collected Offline"
            value={`-${Util.formatAmount(collection?.offline_total ?? 0)}`}
          />
          <CollectedRow
            className="max-w-[480px]"
            title="Cash or Check"
            subtitle={`(received: ${Util.formatAmount(
              collection?.offline_confirmed_total ?? 0,
            )}, pending: ${Util.formatAmount(
              collection?.offline_pending_total ?? 0,
            )})`}
            value={Util.formatAmount(collection?.offline_total ?? 0)}
          />
        </div>

        {collectionDeposits && collectionDeposits.length > 0 && (
          <>
            <WebUI.Separator
              className="[&.Separator]:border-b-[3px]"
              variant="light"
            />

            <div className="flex flex-col gap-2">
              <TotalRow
                label="Total Funded"
                value={Util.formatAmount(collection?.confirmed_deposits ?? 0)}
              />
              <div className="flex flex-col gap-2">
                {collectionDeposits.map((collectionDeposit) => (
                  <TransactionRow
                    key={collectionDeposit.id}
                    className="[&:nth-child(2)]:grow-[2]"
                    content={[
                      Util.formatDateAs(collectionDeposit.created_at) ?? '',
                      collectionDeposit.bank_account
                        ? `Account ending ${collectionDeposit.bank_account.last4}`
                        : '',
                      Util.formatAmount(collectionDeposit.amount_cents / 100),
                      collectionDeposit.paid
                        ? Util.capitalize(collectionDeposit.status ?? '')
                        : 'Pending',
                    ]}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>

      <div className="flex flex-col gap-6 p-5">
        {disputedPayments.length > 0 && (
          <>
            <div className="flex flex-col gap-1">
              <TotalRow
                label="Disputes"
                value={`-${Util.formatAmount(collection?.total_dispute_fees ?? 0)}`}
              />
              <div className="flex flex-col gap-2 px-6">
                {disputedPayments.map((payment) => (
                  <TransactionRow
                    key={payment.id}
                    content={[
                      Util.formatDateAs(payment.dispute?.created_at ?? '') ??
                        '',
                      `(${Util.formatAmount(payment.total ?? '')})`,
                      `${Util.formatAmount(
                        (payment.dispute?.fee_cents ?? 0) / 100,
                      )} fee`,
                      Util.startCase(payment.dispute?.status ?? ''),
                    ]}
                  />
                ))}
              </div>
            </div>

            <WebUI.Separator />
          </>
        )}

        <div className="flex flex-col gap-2">
          <TotalRow
            label="Past Payouts"
            value={Util.formatAmount(collection?.total_withdrawals ?? 0)}
          />
          <div className="flex flex-col gap-2">
            {Util.sort(withdrawals ?? [])
              .desc((w) => w.created_at)
              .map((withdrawal) => {
                const descriptor =
                  withdrawal.payout_method === 'tango'
                    ? withdrawal.tango_card?.detail.recipientName
                    : withdrawal.statement_descriptor
                return (
                  <TransactionRow
                    key={withdrawal.id}
                    className="[&:nth-child(2)]:grow-[2]"
                    content={[
                      Util.formatDateAs(withdrawal.created_at ?? '') ?? '',
                      <span key="payout_method">
                        <span>
                          {withdrawal.payout_method === 'tango'
                            ? 'Gift Card Reward Link'
                            : `Account ending ${withdrawal.stripe_bank_account_id?.slice(-4).padStart(withdrawal.stripe_bank_account_id.length, '*') ?? ''}`}
                        </span>
                        <br />
                        <span className="text-ds-xs">
                          {descriptor ? `(${descriptor})` : <>&nbsp;</>}
                        </span>
                      </span>,
                      Util.formatAmount(withdrawal.amount),
                      withdrawal.payout_method === 'tango'
                        ? formatGiftCardStatus(withdrawal)
                        : Util.capitalize(
                            {
                              pending: 'in transit',
                              paid: 'paid',
                              confirmed: 'paid',
                            }[withdrawal.stripe_status] ??
                              withdrawal.status ??
                              '',
                          ),
                    ]}
                  />
                )
              })}
          </div>
        </div>

        <WebUI.Separator />

        <div className="flex flex-row justify-end">
          <div className="flex basis-[320px] flex-col gap-3 text-ds-sm">
            <TotalRow
              label="Pending Balance"
              value={`-${Util.formatAmount(collection?.online_pending_total ?? 0)}`}
            />
            <TotalRow
              label="Transaction Fees"
              value={`-${Util.formatAmount(collection?.total_user_fees ?? 0)}`}
            />
            {session?.organization === 'moneyminder' && (
              <TotalRow
                label={`${session.organization_data?.name} Platform Fees`}
                value={`-${Util.formatAmount(collection?.total_platform_fees ?? 0)}`}
              />
            )}

            <WebUI.Separator />

            <TotalRow
              className="font-normal"
              label="Available to Transfer"
              value={Util.formatAmount(
                collection?.withdrawal_balance_available ?? 0,
              )}
            />

            {collection?.access.owner &&
              collection.withdrawal_balance_available > 0 && (
                <LinkButton
                  className="mt-3 self-end"
                  to="/withdraw"
                  state={{prevPath: location.pathname}}
                  variant="default"
                >
                  Transfer to Bank
                </LinkButton>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

// MARK: – TotalRow

interface TotalRowProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string
  value: string
}

const TotalRow: React.FC<TotalRowProps> = ({
  label,
  value,
  className,
  ...restProps
}) => (
  <div
    className={WebUI.cn(
      'TotalRow',
      'flex flex-row items-baseline justify-between gap-2 text-ds-sm',
      className,
    )}
    {...restProps}
  >
    <WebUI.Text className="TotalRow-label">{label}</WebUI.Text>
    <WebUI.Text className="TotalRow-value font-light">{value}</WebUI.Text>
  </div>
)

// MARK: – CollectedRow

interface CollectedRowProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
  title: React.ReactNode
  subtitle: string
  value: string
}

const CollectedRow: React.FC<CollectedRowProps> = ({
  title,
  subtitle,
  value,
  className,
  ...restProps
}) => (
  <div
    className={WebUI.cn(
      'CollectedRow',
      'flex flex-row justify-between gap-2 text-ds-sm',
      className,
    )}
    {...restProps}
  >
    <div className="flex flex-col gap-1">
      <span>{title}</span>
      <WebUI.Text className="font-light text-ds-xs">{subtitle}</WebUI.Text>
    </div>
    <span>{value}</span>
  </div>
)

// MARK: – TransactionRow

interface TransactionRowProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'content'> {
  content: Array<string | React.ReactNode>
}

const TransactionRow: React.FC<TransactionRowProps> = ({
  content = ['', '', '', ''],
  className,
  ...restProps
}) => (
  <div
    className={WebUI.cn(
      'flex flex-row gap-1 *:flex-[1_0_0px] *:text-ds-sm',
      className,
    )}
    {...restProps}
  >
    {content.map((column, idx) => (
      <span key={idx}>{column}</span>
    ))}
  </div>
)
