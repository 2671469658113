import * as Yup from 'yup'
import {FormikHandlers, FormikHelpers, FormikState} from 'formik'
import React, {useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {InquireVerificationCode} from 'src/components/InquireVerificationCode'
import * as Util from '@cheddarup/util'
import {
  InferBody,
  api,
  endpoints,
  useCreateWithdrawalMutation,
  useResendWithdrawalToRecipientMutation,
  useTestTangoWithdrawalMutation,
} from '@cheddarup/api-client'
import {Link} from 'src/components/Link'
import GiftCardPresent from 'src/images/GiftCardPresent.gif'
import {formatGiftCardStatus} from 'src/helpers/gift-card-utils'
import {read2FAError} from 'src/helpers/error-formatting'
import config from 'src/config'
import {useForkRef, useFormik} from '@cheddarup/react-util'
import {AddPhoneNumberModal} from 'src/components/AddPhoneNumberModal'

export interface SendGiftCardContainerProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

const SendGiftCardContainer: React.FC<SendGiftCardContainerProps> = ({
  collection,
  className,
  ...restProps
}) => {
  const [selectedGift, setSelectedGift] = useState<Api.Withdrawal | null>(null)
  const resendGiftCardModalRef = useRef<WebUI.DialogInstance>(null)
  const {data: giftTransfers = []} = api.withdrawals.list.useQuery(
    {pathParams: {tabId: collection.id}},
    {
      select: (withdrawals) =>
        withdrawals.filter((w) => w.payout_method === 'tango'),
    },
  )

  return (
    <div
      className={WebUI.cn('gap-2 text-gray800', {color: '$gray800'}, className)}
      {...restProps}
    >
      <WebUI.Heading as="h3">Send a gift card</WebUI.Heading>
      <div className="flex flex-col gap-6">
        <div className="flex max-w-screen-sm flex-col gap-6">
          <WebUI.Text className="font-light text-ds-base">
            Your recipient will receive an email with your message and their
            gift card link, redeemable at Cheddar Up’s Gift Card Store. They can
            select one or multiple gift cards from over 150 popular retailers!{' '}
            <WebUI.Anchor
              href={
                config.isProd
                  ? 'https://www.rl.tangocard.com/r/1/iXqiY8xtlIDAw2k_7O6yvASwhCENFfn5oZM42kHt8TA/catalog-preview'
                  : 'https://www.rl.tangocard.com/r/1/euulsYNwyr86z--ROsa4S6QN__mKlKGgSARYyHvspUw/catalog-preview'
              }
              target="_blank"
            >
              Preview gift card selection here
            </WebUI.Anchor>
          </WebUI.Text>
          <WebUI.Text className="font-light text-ds-base italic">
            Note: Please allow up to 72 hours for your recipient to receive
            their link.
          </WebUI.Text>
        </div>
        <WebUI.Disclosure>
          {(disclosure) => (
            <>
              {!disclosure.visible && (
                <WebUI.DisclosureButton
                  className="self-start"
                  iconBefore={null}
                >
                  Send a Gift Card
                </WebUI.DisclosureButton>
              )}
              <WebUI.DisclosureContent>
                <div className="relative flex flex-col rounded bg-gray100 p-6">
                  <WebUI.DisclosureButton
                    className="absolute top-4 right-4"
                    iconBefore={null}
                    as={WebUI.IconButton}
                  >
                    <WebUI.PhosphorIcon icon="x" width={24} />
                  </WebUI.DisclosureButton>
                  <GiftCardForm
                    collection={collection}
                    onDidSucceed={() => disclosure.hide()}
                  />
                </div>
              </WebUI.DisclosureContent>
            </>
          )}
        </WebUI.Disclosure>

        {giftTransfers?.length > 0 && (
          <>
            <div className="flex flex-col gap-3">
              {giftTransfers?.map((gift) => (
                <GiftCardRow
                  gift={gift}
                  key={gift.id}
                  onResendGift={() => {
                    setSelectedGift(gift)
                    resendGiftCardModalRef.current?.show()
                  }}
                />
              ))}
            </div>
            <ResendGiftCardModal
              gift={selectedGift}
              ref={resendGiftCardModalRef}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default SendGiftCardContainer

// MARK: GiftCardRow

interface GiftCardRowProps extends React.ComponentPropsWithoutRef<'div'> {
  gift: Api.Withdrawal
  onResendGift: () => void
}

const GiftCardRow: React.FC<GiftCardRowProps> = ({
  gift,
  onResendGift,
  className,
  ...restProps
}) => {
  const formattedStatus = formatGiftCardStatus(gift)
  const bounced = formattedStatus === 'Bounced'

  return (
    <div
      data-bounced={bounced}
      className={WebUI.cn(
        'GiftCardRow',
        'grid grid-cols-[1fr_repeat(4,0.7fr)] rounded bg-teal-90 px-4 py-3 text-ds-sm data-[bounced=true]:bg-natural-80 sm:grid-cols-[1fr_repeat(4,2fr)]',
        className,
      )}
      {...restProps}
    >
      <span className="text-black">
        {Util.formatDateAs(gift.created_at, 'date_compact')}
      </span>
      <span className="font-normal">
        {gift.tango_card?.detail.recipientName}
      </span>
      <div className="flex flex-row items-center gap-1">
        {bounced && (
          <WebUI.PhosphorIcon
            className="text-orange-50"
            icon="warning-circle-fill"
            width={15}
          />
        )}
        {formattedStatus}{' '}
        {formattedStatus === 'Opened' &&
          Util.formatDateAs(
            gift.tango_card?.redemption_email?.created_at ?? new Date(),
            'date_compact',
          )}
      </div>
      <WebUI.Button variant="link" onClick={onResendGift}>
        Resend
      </WebUI.Button>
      <span className="text-right">${gift.amount}</span>
    </div>
  )
}

// MARK: ResendGiftCardModal

interface ResendGiftCardModalProps extends WebUI.ModalProps {
  gift: Api.Withdrawal | null
}

const ResendGiftCardModal = React.forwardRef<
  WebUI.DialogInstance,
  ResendGiftCardModalProps
>(({className, gift, ...restProps}, forwardedRef) => {
  const growlActions = WebUI.useGrowlActions()
  const modalRef = useRef<WebUI.DialogInstance>(null)
  const ref = useForkRef(modalRef, forwardedRef)
  const resendWithdrawalToRecipientMutation =
    useResendWithdrawalToRecipientMutation()

  const formattedStatus = formatGiftCardStatus(gift)

  const formik = useFormik({
    initialValues: {
      recipient_email: gift?.tango_card?.detail.recipientEmail ?? '',
      confirm_email: '',
    },
    validationSchema: Yup.object().shape({
      recipient_email: Yup.string().email('Invalid email').required('Required'),
      confirm_email: Yup.string()
        .oneOf([Yup.ref('recipient_email')], 'Emails must match')
        .required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        await resendWithdrawalToRecipientMutation.mutateAsync({
          pathParams: {
            tabId: gift?.tab_id ?? 0,
            withdrawalId: gift?.id ?? 0,
          },
          body: values,
        })
        growlActions.show('success', {
          title: 'Email Sent',
          body: 'Email has been sent to recipient',
        })
        modalRef.current?.hide()
      } catch {
        growlActions.show('error', {
          title: 'Unable to send email',
          body: 'Please contact Cheddar Up',
        })
      }
    },
    enableReinitialize: true,
  })

  return (
    <WebUI.Modal
      ref={ref}
      aria-label="Resend or Retry Gift Card"
      data-resend={formattedStatus === 'Sent'}
      className={WebUI.cn(
        '[&_>_.ModalContentView[data-resend=true]]:bg-teal-90 [&_>_.ModalContentView]:max-w-screen-sm [&_>_.ModalContentView]:bg-natural-80 [&_>_.ModalContentView]:px-6 [&_>_.ModalContentView]:py-8',
        className,
      )}
      initialVisible={false}
      {...restProps}
    >
      <WebUI.ModalCloseButton />
      <form
        className="flex flex-col gap-4"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className="flex flex-col gap-6">
          <WebUI.Text className="text-gray800">
            {gift?.tango_card?.detail.recipientName}
            <br />
            <span className="font-light text-ds-sm">
              {Util.formatAmount(gift?.amount ?? '')}
            </span>
          </WebUI.Text>
          {formattedStatus === 'Bounced' && (
            <WebUI.Text className="font-light text-gray800">
              Your gift card link was sent to{' '}
              <WebUI.Anchor
                href={`mailto:${gift?.tango_card?.detail.recipientEmail}`}
              >
                {gift?.tango_card?.detail.recipientEmail}
              </WebUI.Anchor>{' '}
              but has bounced. This can happen if the email address was entered
              incorrectly or if the recipient’s email client has blah blah….text
              here. <br />
              <br /> If you would like to send to a different email address
              please complete the fields below. We’ll send you a confirmation
              email when your recipient is sent their gift — typically within 72
              hours.
            </WebUI.Text>
          )}
          <div className="flex max-w-[530px] flex-col">
            <WebUI.FormFieldGroup>
              <WebUI.FormField
                label="Recipient Email"
                error={formik.errors.recipient_email}
                required
              >
                <WebUI.Input
                  name="recipient_email"
                  placeholder="Recipient Email"
                  value={formik.values.recipient_email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
              <WebUI.FormField
                label="Confirm Email"
                error={formik.errors.confirm_email}
                required
              >
                <WebUI.Input
                  name="confirm_email"
                  placeholder="Recipient Email"
                  value={formik.values.confirm_email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
            </WebUI.FormFieldGroup>
          </div>

          <WebUI.Button
            className="min-w-[220px] self-start"
            variant="primary"
            type="submit"
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
          >
            {formattedStatus === 'Sent' ? 'Resend Email' : 'Send Email'}
          </WebUI.Button>
          <WebUI.Text className="max-w-[540px] font-light text-ds-sm">
            Gift Card transfers are subject to internal reviews similar to bank
            account transfers, which may result in a delay in the sending of the
            recipient email.
          </WebUI.Text>
        </div>
      </form>
    </WebUI.Modal>
  )
})

// MARK: GiftCardForm

interface GiftCardFormValues
  extends InferBody<typeof endpoints.withdrawals.testTango> {}

interface GiftCardFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit' | 'onReset'> {
  collection: Api.Tab
  onDidSucceed?: () => void
}

const GiftCardForm: React.FC<GiftCardFormProps> = ({
  collection,
  onDidSucceed,
  className,
  ...restProps
}) => {
  const createWithdrawalMutation = useCreateWithdrawalMutation()
  const testTangoWithdrawalMutation = useTestTangoWithdrawalMutation()
  const growlActions = WebUI.useGrowlActions()
  const giftCardConfirmationAlert = useRef<WebUI.DialogInstance>(null)
  const giftCardSuccessAlert = useRef<WebUI.DialogInstance>(null)

  const internationalAlphanumericRegex = /^[\p{L}\p{N}\s]+$/giu
  const TANGO_GIFT_MAX_AMOUNT = 2000

  const formik = useFormik<GiftCardFormValues>({
    initialValues: {
      amount: '',
      payout_method: 'tango',
      from: '',
      recipient_name: '',
      recipient_email: '',
      confirm_email: '',
      recipient_message: '',
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .moreThan(
          0,
          ({more}) => `Must be greater than ${Util.formatAmount(more)}`,
        )
        .max(
          collection.withdrawal_balance_available,
          ({max}) => `Must be less than or equal to ${Util.formatAmount(max)}`,
        )
        .required('Required'),
      from: Yup.string()
        .required('Required')
        .matches(
          internationalAlphanumericRegex,
          'Only alphanumeric characters are allowed',
        ),
      recipient_name: Yup.string()
        .required('Required')
        .matches(
          internationalAlphanumericRegex,
          'Only alphanumeric characters are allowed',
        )
        .test(
          'is-recipient-name-valid',
          'Can not send gift to yourself',
          (value) => value !== collection?.organizer?.name,
        ),
      recipient_email: Yup.string()
        .email('Invalid email')
        .required('Required')
        .test(
          'is-recipient-email-valid',
          'Can not send gift to yourself',
          (value) => value !== collection?.organizer?.email,
        ),
      confirm_email: Yup.string()
        .oneOf([Yup.ref('recipient_email')], 'Emails must match')
        .required('Required'),
      recipient_message: Yup.string().max(
        1000,
        'Message cannot exceed ${max} characters',
      ),
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        const response = await createWithdrawalMutation.mutateAsync({
          pathParams: {
            tabId: collection.id,
          },
          body: {
            ...values,
            amount: Number(values.amount),
          },
        })
        if (!response.error) {
          giftCardSuccessAlert.current?.show()
          formikHelpers.resetForm()
        }
      } catch (err: any) {
        const isPersonaError = Boolean(
          err.response?.data?.type === 'api_error' &&
            err.response.data.errors[0]?.error === 'persona_required',
        )
        const isTooSoon = Boolean(
          err.response?.data?.type === 'api_error' &&
            err.response.data.errors[0]?.error === 'too_soon',
        )
        if (isPersonaError) {
          growlActions.show('error', {
            title: 'Verification Requested',
            body: 'Before you can withdraw, we need a little more information.',
          })
        } else if (isTooSoon) {
          growlActions.show('error', {
            title: 'Slow down!',
            body: `You're moving pretty fast! We can only process one withdrawal per minute on a single collection.`,
          })
        } else {
          const errMsg = read2FAError(err)
          growlActions.show('error', {
            title: errMsg
              ? 'Two-Factor Verification Failed'
              : 'Withdrawal Failed',
            body: errMsg || 'Please contact Cheddar Up',
          })
        }
      }
    },
  })

  const handleFormSubmit = (testEmail = false) => {
    formik
      .validateForm()
      .then(async (errors) => {
        if (Object.keys(errors).length === 0) {
          const amount = Number(formik.values.amount)
          if (amount > TANGO_GIFT_MAX_AMOUNT) {
            growlActions.show('error', {
              body: `Limit for gift card transfers is $${TANGO_GIFT_MAX_AMOUNT}`,
            })
          } else if (testEmail) {
            try {
              await testTangoWithdrawalMutation.mutateAsync({
                pathParams: {
                  tabId: collection.id,
                },
                body: formik.values,
              })

              growlActions.show('success', {
                title: 'Success!',
                body: 'Email sent successfully.',
              })
            } catch {
              growlActions.show('error', {
                body: 'Something went wrong when sending your messages.',
                title: 'Oops!',
              })
            }
          } else {
            giftCardConfirmationAlert.current?.show()
          }
        }
      })
      .catch(() => {
        // noop
      })
  }

  return (
    <form className={WebUI.cn('flex flex-col gap-4', className)} {...restProps}>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <WebUI.Text className="font-light text-ds-base text-gray800">
            {Util.formatAmount(collection.withdrawal_balance_available)}
            &nbsp;Available
          </WebUI.Text>
          <Link
            className="text-ds-sm"
            variant="primary"
            to={`../${collection.id}/summary`}
          >
            Balance Summary
          </Link>
        </div>
        <div className="flex max-w-[530px] flex-col gap-4">
          <WebUI.FormField
            className="sm:max-w-[257px]"
            required
            label="Gift Card Amount"
            error={formik.errors.amount}
          >
            <WebUI.AmountInput
              name="amount"
              readOnly={formik.isSubmitting}
              placeholder="$"
              value={formik.values.amount}
              onValueChange={(newAmount) =>
                formik.setFieldValue('amount', newAmount ?? '')
              }
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>
          <WebUI.FormField
            label="From Name"
            error={formik.errors.from}
            required
          >
            <WebUI.Input
              name="from"
              placeholder="Who is this gift from?"
              value={formik.values.from}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>
          <WebUI.FormField
            label="Recipient Name"
            error={formik.errors.recipient_name}
            required
          >
            <WebUI.Input
              name="recipient_name"
              readOnly={formik.isSubmitting}
              placeholder="Recipient Name"
              value={formik.values.recipient_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>
          <WebUI.FormFieldGroup>
            <WebUI.FormField
              label="Recipient Email"
              error={formik.errors.recipient_email}
              required
            >
              <WebUI.Input
                name="recipient_email"
                placeholder="Recipient Email"
                value={formik.values.recipient_email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
            <WebUI.FormField
              label="Confirm Recipient Email"
              error={formik.errors.confirm_email}
              required
            >
              <WebUI.Input
                name="confirm_email"
                placeholder="Recipient Email"
                value={formik.values.confirm_email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
          </WebUI.FormFieldGroup>
          <WebUI.FormField
            label="Message"
            error={formik.errors.recipient_message}
          >
            <WebUI.Textarea
              name="recipient_message"
              value={formik.values.recipient_message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter an optional message that will be included in the email sent to the recipient with their gift card link."
              rows={3}
            />
          </WebUI.FormField>
        </div>

        <div className="flex flex-col gap-6 sm:flex-row">
          <WebUI.Button
            className="min-w-[220px]"
            variant="primary"
            type="button"
            onClick={() => handleFormSubmit()}
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
          >
            Review and Send
          </WebUI.Button>
          <WebUI.Button
            variant="link"
            onClick={() => handleFormSubmit(true)}
            loading={testTangoWithdrawalMutation.isPending}
            disabled={testTangoWithdrawalMutation.isPending}
          >
            Send me a test email
          </WebUI.Button>
        </div>
        <WebUI.Text className="font-light text-ds-sm">
          Gift Card transfers are subject to internal reviews similar to bank
          account transfers, which may result in a delay in the sending of the
          recipient email.
        </WebUI.Text>
      </div>
      <GiftCardConfirmationAlert
        ref={giftCardConfirmationAlert}
        formik={formik}
      />
      <GiftCardSuccessAlert
        ref={giftCardSuccessAlert}
        onDidHide={onDidSucceed}
      />
    </form>
  )
}

// MARK: GiftCardConfirmationAlert

interface GiftCardConfirmationAlertProps extends WebUI.AlertProps {
  formik: FormikState<GiftCardFormValues> &
    FormikHelpers<GiftCardFormValues> &
    FormikHandlers
}

const GiftCardConfirmationAlert = React.forwardRef<
  WebUI.DialogInstance,
  GiftCardConfirmationAlertProps
>(({formik, className, ...restProps}, forwardedRef) => {
  const {data: session, refetch: refetchSession} = api.auth.session.useQuery()
  const addPhoneNumberVerificationModalRef = useRef<WebUI.DialogInstance>(null)

  return (
    <>
      <WebUI.Alert
        ref={forwardedRef}
        aria-label="Gift card confirmation"
        className={WebUI.cn(
          'sm:[&_>_.ModalContentView]:max-w-[548px]',
          className,
        )}
        {...restProps}
      >
        {(dialog) => (
          <>
            <WebUI.AlertHeader>Review recipient details</WebUI.AlertHeader>
            <WebUI.AlertContentView
              text={
                <div className="flex flex-col gap-5 bg-teal-90 p-5">
                  <WebUI.Text className="text-ds-sm">
                    From: {formik.values.from}
                    <br />
                    Recipient: {formik.values.recipient_name}
                    <br />
                    Recipient's Email: {formik.values.recipient_email}
                    <br />
                    Amount: {Util.formatAmount(formik.values.amount)}
                  </WebUI.Text>
                  {formik.values.recipient_message && (
                    <WebUI.Text className="text-ds-sm">
                      Message for recipient:
                      <br />
                      <i>{formik.values.recipient_message}</i>
                    </WebUI.Text>
                  )}
                </div>
              }
              actions={
                <>
                  <InquireVerificationCode>
                    {(verificationHelpers) => (
                      <WebUI.AlertActionButton
                        execute={async () => {
                          if (session?.user.profile.phone?.verified) {
                            const {verificationCode} = await verificationHelpers
                              .verifyPhone()
                              .catch(() => ({verificationCode: ''}))
                            formik.setFieldValue(
                              'security.token',
                              verificationCode,
                            )
                            await formik.submitForm()
                            dialog.hide()
                          } else {
                            addPhoneNumberVerificationModalRef.current?.show()
                          }
                        }}
                        loading={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                      >
                        Send Gift Card Link
                      </WebUI.AlertActionButton>
                    )}
                  </InquireVerificationCode>
                  <WebUI.AlertCancelButton />
                </>
              }
            />
          </>
        )}
      </WebUI.Alert>

      <AddPhoneNumberModal
        ref={addPhoneNumberVerificationModalRef}
        priorTo="sending gift card"
        onDidVerify={() => {
          refetchSession()
          addPhoneNumberVerificationModalRef.current?.hide()
        }}
      />
    </>
  )
})

// MARK: GiftCardSuccessAlert

const GiftCardSuccessAlert = React.forwardRef<
  WebUI.DialogInstance,
  WebUI.AlertProps
>(({className, ...restProps}, forwardedRef) => (
  <WebUI.Alert
    ref={forwardedRef}
    aria-label="Gift card success"
    className={WebUI.cn('sm:[&_>_.ModalContentView]:max-w-[548px]', className)}
    closeButtonVisible={false}
    {...restProps}
  >
    {(dialog) => (
      <>
        <WebUI.AlertHeader>Someone is going to be thrilled!</WebUI.AlertHeader>
        <WebUI.AlertContentView
          text={
            <div className="flex flex-col items-center gap-5">
              <div className="flex flex-col items-center">
                <WebUI.Text className="font-light">
                  Your gift card link has been initiated. We’ll send you a
                  confirmation email when your recipient is sent their gift —
                  typically within 72 hours.
                </WebUI.Text>
                <img src={GiftCardPresent} width="250px" alt="Gift Card" />
              </div>
              <WebUI.Button onClick={() => dialog.hide()} variant="primary">
                Close
              </WebUI.Button>
            </div>
          }
        />
      </>
    )}
  </WebUI.Alert>
))
