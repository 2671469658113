// @ts-ignore

import {z} from '@cheddarup/util'

import {s3ImageSchema} from './common'

export const userBrandingFooterSchema = z.object({
  contact: z
    .object({
      orgName: z.string(),
      phoneNumber: z.string(),
      streetAddress: z.string(),
    })
    .optional(),
  socialAccounts: z
    .object({
      twitter: z.string(),
      youtube: z.string(),
      facebook: z.string(),
      instagram: z.string(),
    })
    .optional(),
  mainWebsiteLink: z
    .object({
      link: z.string(),
      text: z.string(),
    })
    .optional(),
  verifiedNonProfitBadge: z
    .object({
      enabled: z.boolean(),
    })
    .optional(),
})

export const userBrandingSchema = z.object({
  color_palette: z.object({
    groupPage: z.object({
      enabled: z.boolean(),
      primary: z.string(),
      secondary: z.string(),
      neutral: z.string(),
    }),
    payerPage: z.object({
      enabled: z.boolean(),
      primaryButton: z.string(),
      secondaryButton: z.string(),
    }),
    email: z.object({
      enabled: z.boolean(),
      primaryButton: z.string(),
      primaryButtonText: z.string(),
    }),
  }),
  user_id: z.number(),
  custom_brand_emails: z.boolean(),
  enable_brand_email_colors: z.boolean().optional(),
  enable_brand_logo: z.boolean(),
  logo: s3ImageSchema.nullish(),
  enable_brand_footer: z.boolean(),
  footer: userBrandingFooterSchema,
})

declare global {
  namespace Api {
    // @ts-ignore
    type UserBranding = z.infer<typeof userBrandingSchema>
    // @ts-ignore
    type UserBrandingFooter = z.infer<typeof userBrandingFooterSchema>
  }
}
