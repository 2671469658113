import React, {useEffect, useRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'

import usePublicCollection from './hooks/usePublicCollection'
import {AddPaymentItemViewsBanner} from './components/AddPaymentBanners'
import {CollectionOverview} from './components/CollectionOverview'
import {PayerObjectsLayout} from './components/PayerLayouts'
import {ItemPicker} from './PayerPage/components/ItemPicker/ItemPicker'
import {CollectionPaymentGoalPanel} from './components/PayerInfoPanels'
import {usePayerUIState} from './PayerUIStateProvider'
import {usePayerNavigateToNextSegment} from './utils/payer-navigation-utils'
import {isFormsBeforeItems} from './utils/public-collection-utils'
import {getPayerBrandKitColors, PayerBrandKitColors} from '@cheddarup/core'
import {getCUReadableColor} from 'src/helpers/color-utils'

const PayerItemsPage: React.FC = () => {
  const {publicCollection} = usePublicCollection()
  const noItemsAlertRef = useRef<WebUI.DialogInstance>(null)
  const media = WebUI.useMedia()
  const payerUIState = usePayerUIState()

  const brandKitColors = getPayerBrandKitColors(
    publicCollection.organizer.branding?.color_palette.payerPage,
  )

  return (
    <PayerObjectsLayout>
      <AddPaymentItemViewsBanner />
      {!isFormsBeforeItems(publicCollection) && <CollectionOverview />}
      {!media.lg && publicCollection.paymentGoal?.enabled && (
        <CollectionPaymentGoalPanel />
      )}
      <ItemPicker ref={payerUIState.itemPickerRef} />

      <NoItemsInCartAlert
        ref={noItemsAlertRef}
        brandKitColors={brandKitColors}
      />
    </PayerObjectsLayout>
  )
}

// MARK: – NoItemsInCartAlert

interface NoItemsInCartAlertProps extends WebUI.AlertProps {
  brandKitColors: PayerBrandKitColors
}

const NoItemsInCartAlert = React.forwardRef<
  WebUI.DialogInstance,
  NoItemsInCartAlertProps
>(({visible, onVisibleChange, brandKitColors, ...restProps}, forwardedRef) => {
  const payerUIState = usePayerUIState()
  const payerNavigateToNextSegment = usePayerNavigateToNextSegment()

  const setNoItemsInCartAlertVisible = payerUIState.setNoItemsInCartAlertVisible
  useEffect(
    () => () => {
      setNoItemsInCartAlertVisible(false)
    },
    [setNoItemsInCartAlertVisible],
  )

  return (
    <WebUI.Alert
      ref={forwardedRef}
      aria-label="No items proceed confirmation"
      visible={visible ?? payerUIState.noItemsInCartAlertVisible}
      onVisibleChange={(isVisible) => {
        onVisibleChange?.(isVisible)
        payerUIState.setNoItemsInCartAlertVisible(isVisible)
      }}
      {...restProps}
    >
      <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text="You don’t have any items in your cart. Are you sure you’d like to proceed?"
        actions={
          <>
            <WebUI.AlertActionButton
              style={{
                backgroundColor: brandKitColors.secondaryButton,
                color: getCUReadableColor(brandKitColors.secondaryButton),
              }}
              execute={() => payerNavigateToNextSegment()}
            >
              Continue
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
})

export default PayerItemsPage
