import {ForwardRefComponent} from '@cheddarup/react-util'
import {normalizeUrl} from '@cheddarup/util'
import {Anchor, AnchorProps} from '@cheddarup/web-ui'
import React from 'react'

export interface SmartAnchorProps extends AnchorProps {
  normalizeLink?: boolean
}

export const SmartAnchor = React.forwardRef(
  ({href: hrefProp, normalizeLink, ...restProps}, forwardedRef) => {
    const href = normalizeLink && hrefProp ? normalizeUrl(hrefProp) : hrefProp

    return <Anchor ref={forwardedRef} href={href} {...restProps} />
  },
) as ForwardRefComponent<'a', SmartAnchorProps>
